import React from "react";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { useTranslation } from "react-i18next";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { CollectionDataType, ImageSizes } from "globals/enums/global.enum";
import Row from "components/general.compoenents/row.component/row.component";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import ExerciseStore from "stores/exercise.store";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import StudioStore from "stores/studio.store";
import { navigationHelper } from "globals/helpers/navigation.helper";
import AdvancedListDataTable, {
  SelectType,
} from "components/table.components/advanced.list.data.table.component/advanced.list.data.table.component";
import { LocalSortFilterOption } from "globals/helpers/storage.helper";
import { SearchTopic } from "stores/global.search.store";
import { PaginationType } from "components/footer.component/footer.component";

interface ExerciseListProps {
  fetchExercises: (
    args: LocalSortFilterOption,
    isPagination?: boolean
  ) => Promise<
    Exercise[] | { data: Exercise[]; totalItems: number } | undefined
  >;
  searchExercises: (
    value: string,
    args?: {
      isPagination: boolean;
      isScroll: boolean;
      pageIndex: number;
      itemsInPage: number;
    }
  ) => Promise<
    Exercise[] | { data: Exercise[]; totalItems: number } | undefined
  >;
  onClick?: (exercise: Exercise) => void;
  onSelect?: (data: Exercise | Exercise[]) => void;
  isLoading?: boolean;
  exerciseStore?: ExerciseStore;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

const ExerciseList = ({
  onClick,
  onSelect,
  isLoading = false,
  fetchExercises,
  searchExercises,
  exerciseStore,
  studioStore,
  modalStore,
}: ExerciseListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "inventoryPage.exercises.overview");
  const navigate = navigationHelper();

  const currentLanguage = studioStore?.currentLanguage?.value;
  const selectedExercises = exerciseStore?.selectedExercises;
  const paginationData = exerciseStore?.paginationData;

  const handleArchiveExercise = async (exercise: Exercise): Promise<void> => {
    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        await exerciseStore?.archiveExercise(exercise);
      },
    });
  };

  const handleSelectedExercises = async (
    action: string,
    fetchData: (args: any) => void
  ): Promise<void> => {
    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        if (action === "archive" && selectedExercises) {
          await exerciseStore?.archiveExercises(selectedExercises);
        }

        // clear selected exercises store data
        exerciseStore?.setSelectedExercises([]);

        // get updated Exercise data list after archiving
        fetchData({});
      },
    });
  };

  // render optional buttons for custom bulk actions
  const renderCustomBulkActions = (): JSX.Element => {
    return <></>;
  };

  const toggleSelectMode = (value: boolean): void => {
    // clear selected exercises when click 'Cancel' button
    if (!value && exerciseStore?.selectedExercises.length) {
      exerciseStore?.setSelectedExercises([]);
    }

    exerciseStore?.setSelectMode(value);
  };

  const getMuscleGroup = (
    exercise: Exercise,
    type: "agonist" | "antagonist" | "synergist"
  ): string => {
    const muscleGroups = exercise?.muscleGroups[type] || [];

    if (muscleGroups.length === 0 || currentLanguage == null) {
      return "-";
    }

    // map over the muscle groups and return the name and join them with a comma
    return muscleGroups
      .map((muscleGroup) => muscleGroup?.title?.[currentLanguage])
      .join(", ");
  };

  const getLibraryInfo = (exercise: Exercise): string => {
    const library = exercise?.contentLibrary;
    const type = exercise?.type;

    if (library == null || type === CollectionDataType.STUDIO) {
      return CollectionDataType.STUDIO;
    }

    return library.title[currentLanguage!];
  };

  return (
    <AdvancedListDataTable
      id="exercises"
      sortable={true}
      selectType={SelectType.CHECKBOX}
      searchTopic={SearchTopic.EXERCISE}
      newItemPath="inventory/exercises/new/info"
      translationPath="inventoryPage.exercises.overview"
      fetchData={fetchExercises}
      searchData={searchExercises}
      selectedItems={exerciseStore?.selectedExercises}
      gap={20}
      noDataMessage={translate("noDataMessage")}
      isLoading={isLoading}
      isSelectMode={exerciseStore?.selectMode}
      isPagination={true}
      paginationData={paginationData}
      paginationType={PaginationType.SCROLL}
      onClick={onClick}
      onSelect={onSelect}
      handleSelectedItems={handleSelectedExercises}
      renderCustomBulkActions={renderCustomBulkActions}
      onToggleSelectMode={toggleSelectMode}
      listDropdownMenu={{
        items: [
          {
            label: translate("listDropdownMenu.edit"),
            icon: faEdit,
            onClick: (exercise: Exercise) => {
              if (exercise?._id == null) return;
              navigate(`inventory/exercises/${exercise._id}/info`);
            },
          },
          {
            label: translate("listDropdownMenu.delete"),
            icon: faTrash,
            onClick: (exercise: Exercise) => {
              handleArchiveExercise(exercise);
            },
          },
        ],
        placement: "left",
      }}
      columns={[
        {
          child: <TitleText tableHeader>{translate("title")}</TitleText>,
          flex: 2,
          sortBy: `title.${currentLanguage ?? "de"}`,
        },
        {
          child: <TitleText tableHeader>{translate("agonist")}</TitleText>,
          flex: 1,
          filterBy: "muscleGroups.agonist",
          filterByName: translate("agonist"),
          filterValues: [
            { name: "Latisimus", value: "6409c25d6935bb8d2dde5fa5" },
            { name: "Trizeps Surae", value: "640c67690701ac5590f09e14" },
            { name: "Gluteus", value: "640a336d67b31d7f0d44160a" },
          ],
          sortBy: "muscleGroups.agonist",
        },
        {
          child: <TitleText tableHeader>{translate("synergist")}</TitleText>,
          flex: 1,
          filterBy: "muscleGroups.synergist",
          filterByName: translate("synergist"),
          filterValues: [{ name: "Bizeps", value: "6404f2ebcaf61e7ad8179e65" }],
          sortBy: "muscleGroups.synergist",
        },
        {
          child: <TitleText tableHeader>{translate("antagonist")}</TitleText>,
          flex: 1,
          filterBy: "muscleGroups.antagonist",
          filterByName: translate("antagonist"),
          filterValues: [
            { name: "Deltoideus", value: "6407441a6935bb8d2dde07d0" },
            { name: "Pectoralis", value: "6409c26c6935bb8d2dde5faf" },
            { name: "Abs", value: "6409be546935bb8d2dde5c82" },
          ],
          sortBy: "muscleGroups.antagonist",
        },
        {
          child: <TitleText tableHeader>{translate("library")}</TitleText>,
          flex: 1,
          filterBy: "contentLibrary",
          filterByName: translate("library"),
          filterValues: [
            { name: "Test Library GYMO", value: "665b44cea2b5407370d7e567" },
            { name: "zweite library :)", value: "665e20bf386cacc78c57cf1a" },
          ],
          sortBy: "contentLibrary",
        },
        {
          child: <TitleText tableHeader>{translate("createdAt")}</TitleText>,
          flex: 1,
          filterBy: "system.createdAt",
          filterByName: translate("timeRange"),
          filterValues: [{ name: "2024-04-07", value: "2024-04-07" }],
          sortBy: "system.createdAt",
        },
      ]}
      dataTableItemBuilder={(dataItem: Exercise) => {
        return {
          key: `exercise-item-${dataItem?._id}`,
          children: [
            {
              child: (
                <Row alignItems="center">
                  <Image
                    imageUrl={dataItem?.coverImageUrl}
                    size={ImageSizes.M}
                  />

                  <RunningText className="ml-20">
                    {dataItem?.title?.[currentLanguage!]}
                  </RunningText>
                </Row>
              ),
            },
            {
              child: (
                <RunningText>{getMuscleGroup(dataItem, "agonist")}</RunningText>
              ),
            },
            {
              child: (
                <RunningText>
                  {getMuscleGroup(dataItem, "synergist")}
                </RunningText>
              ),
            },
            {
              child: (
                <RunningText>
                  {getMuscleGroup(dataItem, "antagonist")}
                </RunningText>
              ),
            },
            {
              child: (
                <HighlightedInfoText>
                  {getLibraryInfo(dataItem)}
                </HighlightedInfoText>
              ),
            },
            {
              child: (
                <RunningText>
                  {formatDate(dataItem?.system?.createdAt)}
                </RunningText>
              ),
            },
          ],
          placement: "left",
        } as any;
      }}
    />
  );
};

export default inject(
  "exerciseStore",
  "modalStore",
  "studioStore"
)(observer(ExerciseList));
