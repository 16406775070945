import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./footer.component.scss";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import Select from "react-select";
import IconButton from "components/input.components/icon.button.component/icon.button.component";

export enum PaginationType {
  SCROLL = "SCROLL",
  NAVIGATE = "NAVIGATE",
}

export interface PaginationData {
  pageIndex: number;
  itemsInPage: number;
  totalItems: number;
}

interface DataTableFooterProps {
  type?: PaginationType;
  data: PaginationData;
  selectOptions: number[];
  className?: string;
  handleChangePage?: (page: number) => void;
  handleSelectPageItems?: (page: number) => void;
}

const DataTableFooter = ({
  type = PaginationType.SCROLL,
  data,
  selectOptions,
  className,
  handleChangePage,
  handleSelectPageItems,
}: DataTableFooterProps): JSX.Element => {
  const { pageIndex, itemsInPage, totalItems } = data;

  const [itemsValue, setItemsValue] = useState(null as any);

  useEffect(() => {
    if (itemsInPage) {
      setItemsValue({
        label: itemsInPage,
        value: itemsInPage,
      });
    }
  }, [data]);

  const getOptions = (
    options: number[]
  ): Array<{ label: number; value: number }> => {
    const arr = options.map((item: number) => {
      return { label: item, value: item };
    });
    return arr;
  };

  const displayPageElementsInfo = (): string => {
    const startNumber = pageIndex * itemsInPage + 1;
    const endNumber =
      totalItems < (pageIndex + 1) * itemsInPage
        ? totalItems
        : (pageIndex + 1) * itemsInPage;

    if (type === PaginationType.SCROLL) {
      return `showing ${1}-${endNumber} of ${totalItems} elements`;
    }

    if (type === PaginationType.NAVIGATE) {
      return `showing ${startNumber}-${endNumber} of ${totalItems} elements`;
    }

    return "";
  };

  const dataTableFooterClassName = classNames(
    {
      "data-table-footer": true,
      "data-table-footer--select": type === PaginationType.NAVIGATE,
    },
    className
  );

  return (
    <div className={dataTableFooterClassName}>
      {type === PaginationType.SCROLL && totalItems != null && (
        <span>{displayPageElementsInfo()}</span>
      )}

      {type === PaginationType.NAVIGATE && totalItems != null && (
        <>
          <span>{displayPageElementsInfo()}</span>

          <div className="page-select-container">
            <div className="page-select-section">
              <span>{"Rows per page"}</span>
              <Select
                menuPlacement={"top"}
                className="page-select"
                classNamePrefix="page-select-dropdown"
                value={itemsValue ?? null}
                options={getOptions(selectOptions)}
                onChange={(option) => {
                  if (handleSelectPageItems) {
                    handleSelectPageItems(option.value);
                  }
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>

            <div className="page-navigate-section">
              <IconButton
                disabled={pageIndex === 0}
                icon={faAngleLeft}
                className="page-navigate-section-button"
                onClick={() => {
                  if (pageIndex === 0) return;

                  if (handleChangePage) {
                    handleChangePage(pageIndex - 1);
                  }
                }}
              />
              <div>
                <span className="current-page-number">{`${
                  pageIndex + 1
                }`}</span>
                <span>{` of ${Math.ceil(totalItems / itemsInPage)}`}</span>
              </div>
              <IconButton
                disabled={pageIndex === Math.floor(totalItems / itemsInPage)}
                icon={faAngleRight}
                className="page-navigate-section-button"
                onClick={() => {
                  if (pageIndex === Math.floor(totalItems / itemsInPage))
                    return;

                  if (handleChangePage) {
                    handleChangePage(data.pageIndex + 1);
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataTableFooter;
