import React, { SelectHTMLAttributes, useState, useEffect } from "react";
import "./new.dropdown.component.scss";
import classNames from "classnames";
import Select, { ActionMeta } from "react-select";
import Image from "components/image.component/image.component";

export enum MultiSelectionType {
  COUNT = "COUNT",
  IMAGE = "IMAGE",
  WRAP = "WRAP",
}

export enum SelectOptionType {
  DEFAULT = "DEFAULT",
  IMAGE = "IMAGE",
  ICON = "ICON",
  META_DATA = "META_DATA",
}

export interface Option {
  label: string;
  value: string;
  image?: string;
  icon?: string;
  metaData?: string;
}

interface NewSelectDropDownProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  options: any[];
  multiSelectionType?: MultiSelectionType;
  selectOptionType?: SelectOptionType;
  isSelectedOptionsFirst?: boolean;
  isHideSelectedOptions?: boolean;
  onChange?: (value: any, actionMeta?: ActionMeta<any>) => void;
  selectedItem?: any;
  label?: string;
  readOnly?: boolean;
  labelPropertyName?: string;
  valuePropertyName?: string;
  imagePropertyName?: string;
  iconPropertyName?: string;
  metaDataPropertyName?: string;
  className?: any;
  isLoading?: boolean;
  menuIsOpen?: boolean;
  maxMenuHeight?: number;
  validationMessage?: string;
  inputRef?: any;
  global?: boolean;
  selectImageLabelNumber?: number;
}

const NewSelectDropDown = ({
  required = false,
  options,
  multiSelectionType = MultiSelectionType.COUNT,
  selectOptionType = SelectOptionType.DEFAULT,
  isSelectedOptionsFirst,
  isHideSelectedOptions = false,
  onChange,
  selectedItem = undefined,
  multiple = false,
  label,
  disabled = false,
  readOnly = false,
  labelPropertyName = "label",
  valuePropertyName = "value",
  imagePropertyName = "image",
  iconPropertyName = "icon",
  metaDataPropertyName = "metaData",
  className,
  isLoading = false,
  menuIsOpen,
  maxMenuHeight = 240,
  validationMessage,
  inputRef,
  global = true,
  selectImageLabelNumber = 4,
}: NewSelectDropDownProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<any>(selectedItem);
  const [orderedOptions, setOrderedOptions] = useState(options);
  const [isSelectFocus, setSelectFocus] = useState(false);

  useEffect(() => {
    if (onChange != null && selectedItem == null) {
      onChange(selectedItem);
    }
  }, [selectedItem]);

  const getOptionLabel = (option: any): any => {
    if (multiple) {
      switch (selectOptionType) {
        case SelectOptionType.IMAGE: {
          const image = option[imagePropertyName];
          return (
            <div className={selectOptionClassName}>
              <Image imageUrl={image} className="defaul-option-field--image" />
              <span>{resolve(labelPropertyName, option)}</span>
            </div>
          );
        }
        case SelectOptionType.ICON: {
          const icon = option[iconPropertyName];
          return (
            <div className={selectOptionClassName}>
              {icon && (
                <Image imageUrl={icon} className="defaul-option-field--icon" />
              )}
              {!icon && <div></div>}
              <span>{resolve(labelPropertyName, option)}</span>
            </div>
          );
        }
        case SelectOptionType.META_DATA: {
          return (
            <div className={selectOptionClassName}>
              <span>{resolve(labelPropertyName, option)}</span>
              <span>{resolve(metaDataPropertyName, option)}</span>
            </div>
          );
        }
        case SelectOptionType.DEFAULT: {
          return (
            <div className={selectOptionClassName}>
              <span>{resolve(labelPropertyName, option)}</span>
            </div>
          );
        }
      }
    }

    return (
      <div className={selectOptionClassName}>
        <span>{resolve(labelPropertyName, option)}</span>
      </div>
    );
  };

  const resolve = (path: string, obj: any): any => {
    return path.split(".").reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
    }, obj || self);
  };

  const orderOptions = (): void => {
    if (selectedValue) {
      // get values of selected options
      const selectedValues: string[] = Array.isArray(selectedValue)
        ? selectedValue.map((option: Option) => option.value)
        : [selectedValue?.value];

      // get non-selected options
      const filteredOptions = options.filter(
        (option: Option) => !selectedValues.includes(option.value)
      );

      // get ordered list of options
      const ordered: Option[] = Array.isArray(selectedValue)
        ? [...selectedValue, ...filteredOptions]
        : [...[selectedValue], ...filteredOptions];

      setOrderedOptions(ordered);
    }
  };

  const _buildSelectImageLabel = (): JSX.Element => {
    const labelOptions = [...selectedValue];
    labelOptions.length = selectImageLabelNumber;
    return (
      <div className={selectImageClassName}>
        {labelOptions.map((option: Option, index: number) => {
          return (
            <div
              key={index}
              className="default-image-field--image"
              style={{
                zIndex: selectImageLabelNumber - index,
                backgroundImage: option.image
                  ? "url(" + option.image + ")"
                  : "",
                right: `${8 + index * 8}px`,
              }}
            />
          );
        })}
        {selectedValue.length > selectImageLabelNumber && (
          <span>{`+${selectedValue.length - selectImageLabelNumber}`}</span>
        )}
      </div>
    );
  };

  const selectContainerClassName = classNames(
    {
      "default-select-container": true,
      "default-select-container--readonly": readOnly,
    },
    className
  );

  const reactSelectContainerClassName = classNames({
    "react-select-container": true,
    "react-select-container--disabled": disabled,
    "react-select-container--readonly": readOnly,
    "react-select-container--error": validationMessage != null,
  });

  const selectLabelClassName = classNames({
    "default-select-label": label != null,
    "default-select-label--disabled": disabled,
    "default-select-label--readonly": readOnly,
    "default-select-label--on-top":
      !multiple && (isSelectFocus || selectedValue),
    "default-select-label--on-top-wrap":
      multiple &&
      multiSelectionType === MultiSelectionType.WRAP &&
      (isSelectFocus || selectedValue?.length),
    "default-select-label--error": validationMessage != null,
  });

  const selectRequiredClassName = classNames({
    "default-required-field": true,
    "default-required-field--disabled": disabled,
    "default-required-field--readonly": readOnly,
    "default-required-field--error": validationMessage != null,
  });

  const selectCountClassName = classNames({
    "default-count-field": true,
  });

  const selectImageClassName = classNames({
    "default-image-field": true,
  });

  const selectOptionClassName = classNames({
    "defaul-option-field": true,
    "defaul-option-field--disabled": disabled,
    "defaul-option-field--readonly": readOnly,
    "defaul-option-field--image":
      multiple && selectOptionType === SelectOptionType.IMAGE,
    "defaul-option-field--icon":
      multiple && selectOptionType === SelectOptionType.ICON,
    "defaul-option-field--meta-data":
      multiple && selectOptionType === SelectOptionType.META_DATA,
  });

  return (
    <div className={selectContainerClassName}>
      <input {...inputRef} type="hidden" />
      <Select
        menuPlacement={"bottom"}
        className={reactSelectContainerClassName}
        classNamePrefix="default-select-dropdown"
        menuIsOpen={menuIsOpen && !readOnly}
        openMenuOnClick={!readOnly}
        openMenuOnFocus={!readOnly}
        maxMenuHeight={maxMenuHeight}
        menuPortalTarget={global ? document.body : null}
        styles={{
          menuPortal: (base) => ({
            ...base,
            offset: 3,
            zIndex: 9999,
            marginTop: "4px",
          }),
        }}
        isLoading={isLoading}
        // not display selected values in the control on multi selection mode
        controlShouldRenderValue={
          !multiple ||
          (multiple && multiSelectionType === MultiSelectionType.WRAP)
        }
        hideSelectedOptions={isHideSelectedOptions} // show all options on multi selection mode
        // blurInputOnSelect={multiple} // hide focus from the input if there are selected options
        closeMenuOnSelect={false} // close menu after option's selection
        getOptionLabel={(option: any) => getOptionLabel(option)}
        getOptionValue={(option: any) => resolve(valuePropertyName, option)}
        value={selectedValue ?? null}
        onKeyDown={(e) => {
          if (readOnly) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onChange={(value) => {
          setSelectedValue(value);

          if (onChange != null) {
            onChange(value);
          }
        }}
        onFocus={(e) => {
          if (readOnly) {
            e.preventDefault();
          } else {
            setSelectFocus(true);
          }
        }}
        onBlur={() => setSelectFocus(false)}
        onMenuOpen={() => isSelectedOptionsFirst && orderOptions()}
        options={orderedOptions}
        isMulti={multiple}
        isDisabled={disabled || readOnly}
        placeholder=""
      />
      {label != null && (
        <label aria-required className={selectLabelClassName}>
          {validationMessage ?? label}
          {required && <span className={selectRequiredClassName}> *</span>}
        </label>
      )}
      {/* set select info label if MultiSelectionType.COUNT  */}
      {multiple &&
        selectedValue &&
        multiSelectionType === MultiSelectionType.COUNT && (
          <div className={selectCountClassName}>
            <span>{selectedValue.length}</span>
            <button onClick={() => setSelectedValue(undefined)}></button>
          </div>
        )}
      {/* set select info label if MultiSelectionType.IMAGE  */}
      {multiple &&
        selectedValue &&
        multiSelectionType === MultiSelectionType.IMAGE &&
        _buildSelectImageLabel()}
    </div>
  );
};

export default NewSelectDropDown;
