import React, { useState } from "react";
import "./date.range.component.scss";
import classNames from "classnames";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { formatDate } from "globals/helpers/global.helper";
import * as locale from "date-fns/locale";

export interface Range {
  startDate: string | undefined;
  endDate: string | undefined;
}

interface OutlinedDateRangeProps {
  className?: string;
  localization?: any;
  calendarSize?: "large" | "normal";
  onChange?: (value: any) => void;
}

const OutlinedDateRange = ({
  className,
  localization = locale.enUS,
  calendarSize = "large",
  onChange,
}: OutlinedDateRangeProps): JSX.Element => {
  // set date values for the DateRange widget
  const [calendarStartDate, setCalendarStartDate] = useState<Date>(new Date());
  const [calendarEndDate, setCalendarEndDate] = useState<Date>(new Date());

  const selectionRange = {
    startDate: calendarStartDate,
    endDate: calendarEndDate,
    key: "selection",
  };

  const handleSelectCalendarDateRange = (range: any): void => {
    const {
      selection: { startDate, endDate },
    } = range;

    setCalendarStartDate(startDate);
    setCalendarEndDate(endDate);

    const formatedStartDate = formatDate(startDate, "YYYY-MM-DD");
    const formatedEndDate = formatDate(endDate, "YYYY-MM-DD");

    if (onChange) {
      onChange({
        startDate: formatedStartDate,
        endDate: formatedEndDate,
      });
    }
  };

  const dateRangeContainerClassName = classNames(
    {
      "date-range-container": true,
    },
    className
  );

  return (
    <div className={dateRangeContainerClassName}>
      <DateRange
        className={calendarSize === "large" ? "large-size" : ""}
        months={calendarSize === "large" ? 2 : 1}
        direction="horizontal"
        locale={locale[localization as keyof typeof locale]}
        ranges={[selectionRange]}
        onChange={handleSelectCalendarDateRange}
      />
    </div>
  );
};

export default OutlinedDateRange;
