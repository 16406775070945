import React, { useEffect } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import MainLayout from "layouts/main.layout/main.layout";
import marketingIllustrations from "assets/illustrations/invitation.png";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import { ModalStore } from "stores/modal.store";
import ExerciseStore from "stores/exercise.store";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes, useLocation } from "react-router";
import ExerciseList from "./components/exercise.list.component/exercise.list.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import Column from "components/general.compoenents/column.component/column.component";
import {
  LocalSortFilterOption,
  FilterValue,
} from "globals/helpers/storage.helper";

interface InventoryOverviewPageProps {
  exerciseStore?: ExerciseStore;
  modalStore?: ModalStore;
}

const InventoryOverviewPage = ({
  exerciseStore,
  modalStore,
}: InventoryOverviewPageProps): JSX.Element => {
  const location = useLocation();
  const avtiveTab = location.pathname.split("/").pop();

  const navigate = navigationHelper();
  const { t } = useTranslation();
  const translate = createTranslate(t, `inventoryPage.${avtiveTab}.overview`);

  const exercises = exerciseStore?.exercises;
  const selectedExercises = exerciseStore?.selectedExercises;

  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    return () => {
      exerciseStore?.setSelectMode(false);
      exerciseStore?.setSelectedExercises([]);
    };
  }, [exerciseStore]);

  const initializePage = async (): Promise<void> => {
    await exerciseStore?.fetchAndSetExercises({});
  };

  const handleClickItem = (
    item: any,
    inventoryType: "exercises",
    isNew?: boolean
  ): void => {
    if (exerciseStore?.selectMode && selectedExercises) {
      const selectedItemIndex = selectedExercises?.findIndex(
        (exercise: Exercise) => exercise._id === item._id
      );

      if (selectedItemIndex === -1) {
        exerciseStore?.setSelectedExercises([...selectedExercises, item]);
      } else {
        selectedExercises.splice(selectedItemIndex, 1);
        exerciseStore?.setSelectedExercises([...selectedExercises]);
      }
    } else {
      if (item?._id != null && !isNew) {
        navigate(`inventory/${inventoryType}/${item?._id}/info`);
      } else {
        navigate(`inventory/${inventoryType}/new/info`);
      }
    }
  };

  const handleSelectExercise = (data: Exercise | Exercise[]): void => {
    if (exerciseStore?.selectMode && selectedExercises) {
      if (Array.isArray(data)) {
        if (!data.length) {
          exerciseStore?.setSelectedExercises([]);
        } else {
          exerciseStore?.setSelectedExercises(data);
        }
      } else {
        const selectedItemIndex = selectedExercises?.findIndex(
          (exercise: Exercise) => exercise._id === data._id
        );

        if (selectedItemIndex === -1) {
          exerciseStore?.setSelectedExercises([...selectedExercises, data]);
        } else {
          selectedExercises.splice(selectedItemIndex, 1);
          exerciseStore?.setSelectedExercises([...selectedExercises]);
        }
      }
    }
  };

  const getFilterQuery = (filterValues: FilterValue[]): string => {
    const result: any = {};

    filterValues.forEach((filter) => {
      const { columnValue, values } = filter;
      result[columnValue] = values.map((option) => option.value);
    });
    return JSON.stringify(result);
  };

  const fetchExercises = async (
    args: LocalSortFilterOption
  ): Promise<
    Exercise[] | { data: Exercise[]; totalItems: number } | undefined
  > => {
    const query: any = { refresh: true };

    if (args.filterValues?.length) {
      query.filter = getFilterQuery(args.filterValues);
    }

    if (args.sortByProp && args.sortOrder) {
      const sortQuery = `${args.sortOrder === "desc" ? "-" : ""}${
        args.sortByProp
      }`;
      query.sort = sortQuery;
    }

    if (args.isPagination) {
      query.isPagination = args.isPagination;
    }

    if (args.isScroll) {
      query.isScroll = args.isScroll;
    }

    if (args.pageIndex) {
      query.pageIndex = args.pageIndex;
    }

    if (args.itemsInPage) {
      query.itemsInPage = args.itemsInPage;
    }

    return await exerciseStore?.fetchAndSetExercises({ ...query });
  };

  const searchExercises = async (
    value: string,
    args?: {
      isPagination: boolean;
      isScroll: boolean;
      pageIndex: number;
      itemsInPage: number;
    }
  ): Promise<
    Exercise[] | { data: Exercise[]; totalItems: number } | undefined
  > => {
    await exerciseStore?.searchAndSetExercises(value, args);
    return exerciseStore?.exercisesSearchResult;
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        showBorder
        tabs={[
          <Tab
            label={t("inventoryPage.exercises.overview.tabTitle")}
            key="exercises-tab"
            path="exercises"
            rootPath={"inventory"}
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  const _buildExerciseList = (): JSX.Element => {
    return (
      <Column style={{ height: "100%" }}>
        <ExerciseList
          isLoading={exercises?.isLoading}
          fetchExercises={fetchExercises}
          searchExercises={searchExercises}
          onClick={(exercise: Exercise) => {
            handleClickItem(exercise, avtiveTab as any, false);
          }}
          onSelect={(data: Exercise | Exercise[]) => {
            handleSelectExercise(data);
          }}
        />
      </Column>
    );
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper>
          <PageIntroCard
            image={marketingIllustrations}
            imagePosition="bottom center"
            title={translate("introTitle")}
            collapsedTitle={translate("collapsedIntroTitle")}
            description={translate("introDescription")}
            id="inventory-overview-page"
            renderButtons={() => (
              <FilledButton
                onClick={() =>
                  handleClickItem(undefined, avtiveTab as "exercises", true)
                }
                className="mt-10"
                label={translate("addButton")}
              />
            )}
          />

          {_buildTabs()}

          <Routes>
            <Route path="exercises" element={_buildExerciseList()} />
          </Routes>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "modalStore",
  "exerciseStore"
)(observer(InventoryOverviewPage));
