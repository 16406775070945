import React from "react";
import { inject, observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import StudioStore from "stores/studio.store";
import CustomerStore from "stores/customer.store";
import { toast } from "react-toastify";
import trash from "assets/icons/trash.svg";

import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Column from "components/general.compoenents/column.component/column.component";
import Grid from "components/general.compoenents/grid.component/grid.component";

import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import OutlinedDateInput from "components/input.components/outlined.date.input.component/outlined.date.input.component";
import NewSelectDropDown, {
  MultiSelectionType,
  SelectOptionType,
} from "components/input.components/dropdown.components/select.dropdown.component/new.dropdown.component";
import UnitInput, {
  UNIT_TYPE,
} from "components/input.components/unit.input.component/unit.input.component";
import TextAreaComponent from "components/input.components/text.area.component/text.area.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";

export const testFormSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().notRequired(),
  age: yup.number().integer().min(1).notRequired(),
  email: yup.string().email().required("Email is required"),
  address: yup.string().notRequired(),
  description: yup.string().required("Description is required"),
  date: yup.object().notRequired(),
});

interface ComponentTestPageProps {
  customerStore?: CustomerStore;
  studioStore?: StudioStore;
}

const ComponentTestPage = ({
  customerStore,
  studioStore,
}: ComponentTestPageProps): JSX.Element => {
  const currentCustomer = customerStore?.currentCustomer?.data;
  const currentLanguage = studioStore?.currentLanguage;

  if (currentCustomer && currentLanguage) {
    console.log(
      "currentCustomer: ",
      JSON.parse(JSON.stringify(currentCustomer))
    );
    console.log(
      "currentLanguage: ",
      JSON.parse(JSON.stringify(currentLanguage))
    );
  }

  const testForm = useForm({
    resolver: yupResolver(testFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    values: { ...currentCustomer, type: "STUDIO" } as any,
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = testForm;

  // const formsAreDirty = [testForm].some(
  //   (form) => form.formState.isDirty
  // );

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  // handle test form submit
  const onSubmit = async (data: any): Promise<void> => {};

  const sexOptions = [
    {
      label: "Weiblich",
      value: "FEMALE",
      image:
        "https://storage.gymo.io/training-plans/b834be1130971b6fece0dbc57b78a75aaff0c9687019fd3fb79aa628521f4421.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Männlich",
      value: "MALE",
      image:
        "https://storage.gymo.io/training-plans/3ead47e3f8b4c65cdc1c8e29d58b3d607db2423c8d85ea0d554697350289902d.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Divers",
      value: "OTHER",
      image:
        "https://storage.gymo.io/training-plans/d5519ec224a15f8cb06896dc077d8061637df91835f710ba957962e14080f5b3.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test1",
      value: "TEST1",
      image:
        "https://storage.gymo.io/training-plan/7152407e0c900d266fb031144762c984c48d4194c96e45dd0569e5c2f10c1d72.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test2",
      value: "TEST2",
      image:
        "https://storage.gymo.io/training-plans/b834be1130971b6fece0dbc57b78a75aaff0c9687019fd3fb79aa628521f4421.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test3",
      value: "TEST3",
      image:
        "https://storage.gymo.io/training-plans/3ead47e3f8b4c65cdc1c8e29d58b3d607db2423c8d85ea0d554697350289902d.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test4",
      value: "TEST4",
      image:
        "https://storage.gymo.io/training-plans/d5519ec224a15f8cb06896dc077d8061637df91835f710ba957962e14080f5b3.png",
      icon: trash,
      metaData: "test",
    },
    {
      label: "Test5",
      value: "TEST5",
      image:
        "https://storage.gymo.io/training-plan/7152407e0c900d266fb031144762c984c48d4194c96e45dd0569e5c2f10c1d72.png",
      icon: trash,
      metaData: "test",
    },
  ];

  const handleTextInputChange = (value: any): void => {
    console.log("Text input value:", value);
  };

  const handleDescriptionChange = (value: any): void => {
    console.log("Description value:", value);
  };

  const handleDateChange = (dateData: {
    type: "single" | "range";
    value: string | Range;
  }): void => {
    console.log("Date value:", dateData);
  };

  const handleUnitInputChange = (value: any): void => {
    console.log("Unit input value:", value);
  };

  const handleChangeSexOption = (item: any): void => {
    console.log("handleChangeSexOption item:", item);

    setValueAndMarkDirty("sex", item?.value);
    clearErrors("sex");
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper maxSize="form">
          <form
            id="component-test-form"
            onSubmit={handleSubmit(onSubmit, (errors) => {
              console.error(errors);
              toast.error("onSubmitError");
            })}
          >
            <ComponentWrapper className="mb-15">
              <SmallHeadline className="mb-10">
                Text input components
              </SmallHeadline>
              <Column className="mb-20">
                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Required text input</label>
                    <OutlinedTextInput
                      value="Test"
                      required={true}
                      label="First Name"
                      inputRef={register("firstName")}
                      inputRefValue={getValues("firstName")}
                      validationMessage={errors.firstName?.message?.toString()}
                      onChange={handleTextInputChange}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Default text input with a placeholder</label>
                    <OutlinedTextInput
                      label="Last Name"
                      placeholder="Last name"
                      inputRef={register("lastName")}
                      inputRefValue={getValues("lastName")}
                      validationMessage={errors.lastName?.message?.toString()}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Read-only text input</label>
                    <OutlinedTextInput
                      readOnly={true}
                      required={true}
                      value="Test"
                      label="Test Name"
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Disabled text input</label>
                    <OutlinedTextInput label="Account Name" disabled={true} />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Disabled text input with a value</label>
                    <OutlinedTextInput
                      disabled={true}
                      required={true}
                      label="Address"
                      value="Home address"
                      inputRef={register("address")}
                      inputRefValue={getValues("address")}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Text input type=number</label>
                    <OutlinedTextInput type="number" label="Age" />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Text input type=number with a placeholder</label>
                    <OutlinedTextInput
                      type="number"
                      label="Age"
                      placeholder="Age"
                      inputRef={register("age")}
                      inputRefValue={getValues("age")}
                      validationMessage={errors.age?.message?.toString()}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Text input with an error message</label>
                    <OutlinedTextInput
                      label="Email"
                      value="test"
                      validationMessage="The email is invalid"
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Required text input with an error message</label>
                    <OutlinedTextInput
                      required={true}
                      label="Email"
                      value="test"
                      inputRef={register("email")}
                      inputRefValue={getValues("email")}
                      validationMessage="The email is invalid"
                    />
                  </Column>
                </Grid>
              </Column>

              <SmallHeadline className="mb-10">
                Text area components
              </SmallHeadline>
              <Column className="mb-20">
                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Required text area (default rows=5)</label>
                    <TextAreaComponent
                      required={true}
                      label="Description"
                      inputRef={register("description")}
                      inputRefValue={getValues("description")}
                      validationMessage={errors.description?.message?.toString()}
                      onChange={handleDescriptionChange}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Default text area with a placeholder</label>
                    <TextAreaComponent
                      label="Description"
                      placeholder="Description placeholder"
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Read-only text area</label>
                    <TextAreaComponent
                      readOnly={true}
                      value="Test description"
                      label="Description"
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Disabled text area</label>
                    <TextAreaComponent
                      disabled={true}
                      value="Test description"
                      label="Description"
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Text area with an error message</label>
                    <TextAreaComponent
                      label="Description"
                      validationMessage="This description is invalid"
                    />
                  </Column>
                </Grid>
              </Column>

              <SmallHeadline className="mb-10">
                Datepicker components
              </SmallHeadline>
              <Column className="mb-20">
                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Default Date picker</label>
                    <OutlinedDateInput
                      localization={currentLanguage?.value}
                      onChange={handleDateChange}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Date picker with a value</label>
                    <OutlinedDateInput
                      value={"2024-11-28"}
                      localization={currentLanguage?.value}
                      disabledDates={[
                        new Date(),
                        new Date("2024-10-15"),
                        new Date("2024-10-20"),
                      ]}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(3, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Date picker with disabled dates</label>
                    <OutlinedDateInput
                      localization={currentLanguage?.value}
                      disabledDates={[
                        new Date("2024-10-15"),
                        new Date("2024-10-20"),
                        new Date("2024-10-28"),
                      ]}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Read-only Date picker</label>
                    <OutlinedDateInput
                      value="2024-11-28"
                      readOnly={true}
                      localization={currentLanguage?.value}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Disabled Date picker</label>
                    <OutlinedDateInput
                      value="2024-11-28"
                      disabled={true}
                      localization={currentLanguage?.value}
                    />
                  </Column>
                </Grid>

                <Column className="mb-10" gap={5}>
                  <label>Date Range (normal size)</label>
                  <OutlinedDateInput
                    calendarSize="normal"
                    localization={currentLanguage?.value}
                    dateRange={{
                      startDate: undefined,
                      endDate: undefined,
                    }}
                    onChange={handleDateChange}
                  />
                </Column>

                <Column className="mb-10" gap={5}>
                  <label>Date Range (large size)</label>
                  <OutlinedDateInput
                    localization={currentLanguage?.value}
                    dateRange={{
                      startDate: undefined,
                      endDate: undefined,
                    }}
                    onChange={handleDateChange}
                  />
                </Column>

                <Column className="mb-10" gap={5}>
                  <label>Date Range (large size) with disabled dates</label>
                  <OutlinedDateInput
                    localization={currentLanguage?.value}
                    dateRange={{
                      startDate: undefined,
                      endDate: undefined,
                    }}
                    disabledDates={[
                      new Date("2024-10-15"),
                      new Date("2024-10-20"),
                      new Date("2024-10-28"),
                    ]}
                  />
                </Column>

                <Column className="mb-10" gap={5}>
                  <label>Read-only Date Range</label>
                  <OutlinedDateInput
                    readOnly={true}
                    dateRange={{
                      startDate: "2024-10-10",
                      endDate: "2024-10-25",
                    }}
                  />
                </Column>

                <Column className="mb-10" gap={5}>
                  <label>Disabled Date Range</label>
                  <OutlinedDateInput
                    disabled={true}
                    dateRange={{
                      startDate: "2024-10-10",
                      endDate: "2024-10-25",
                    }}
                  />
                </Column>
              </Column>

              <SmallHeadline className="mb-10">
                Select dropdown components
              </SmallHeadline>
              <Column className="mb-20">
                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Default Dropdown (single selection)</label>
                    <NewSelectDropDown
                      value={undefined}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      inputRef={register("sex")}
                      selectedItem={undefined}
                      onChange={handleChangeSexOption}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Required Dropdown (single selection)</label>
                    <NewSelectDropDown
                      value={undefined}
                      required={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      onChange={handleChangeSexOption}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Read-only Dropdown</label>
                    <NewSelectDropDown
                      readOnly={true}
                      value={undefined}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={{
                        label: "Weiblich",
                        value: "FEMALE",
                      }}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Disabled Dropdown</label>
                    <NewSelectDropDown
                      disabled={true}
                      value={undefined}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={{
                        label: "Weiblich",
                        value: "FEMALE",
                      }}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Dropdown with an error message</label>
                    <NewSelectDropDown
                      value={undefined}
                      required={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      validationMessage="This field is required"
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Default Multi Dropdown (COUNT)</label>
                    <NewSelectDropDown
                      value={undefined}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      multiple={true}
                      multiSelectionType={MultiSelectionType.COUNT}
                      onChange={handleChangeSexOption}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Multi Dropdown with first selected options</label>
                    <NewSelectDropDown
                      value={undefined}
                      isSelectedOptionsFirst={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      multiple={true}
                      multiSelectionType={MultiSelectionType.COUNT}
                      onChange={handleChangeSexOption}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Multi Dropdown images (IMAGE)</label>
                    <NewSelectDropDown
                      value={undefined}
                      isSelectedOptionsFirst={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      multiple={true}
                      multiSelectionType={MultiSelectionType.IMAGE}
                      onChange={handleChangeSexOption}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Multi Dropdown values (WRAP)</label>
                    <NewSelectDropDown
                      value={undefined}
                      isSelectedOptionsFirst={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      multiple={true}
                      multiSelectionType={MultiSelectionType.WRAP}
                      onChange={handleChangeSexOption}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(3, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Image options </label>
                    <NewSelectDropDown
                      value={undefined}
                      isSelectedOptionsFirst={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      multiple={true}
                      multiSelectionType={MultiSelectionType.COUNT}
                      selectOptionType={SelectOptionType.IMAGE}
                      onChange={handleChangeSexOption}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Icon options</label>
                    <NewSelectDropDown
                      value={undefined}
                      isSelectedOptionsFirst={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      multiple={true}
                      multiSelectionType={MultiSelectionType.COUNT}
                      selectOptionType={SelectOptionType.ICON}
                      onChange={handleChangeSexOption}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>MetaData options</label>
                    <NewSelectDropDown
                      value={undefined}
                      isSelectedOptionsFirst={true}
                      label="Sex"
                      className="mb-10"
                      options={sexOptions}
                      selectedItem={undefined}
                      multiple={true}
                      multiSelectionType={MultiSelectionType.COUNT}
                      selectOptionType={SelectOptionType.META_DATA}
                      onChange={handleChangeSexOption}
                    />
                  </Column>
                </Grid>
              </Column>

              <SmallHeadline className="mb-10">
                Unit input components
              </SmallHeadline>
              <Column>
                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Default Unit input with values</label>
                    <UnitInput
                      value={100}
                      unitValue={"m"}
                      label="Length"
                      unitType={UNIT_TYPE.LENGTH}
                      initialValue={60}
                      onChange={handleUnitInputChange}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Required Unit input options</label>
                    <UnitInput
                      value={undefined}
                      required={true}
                      label="Weight"
                      unitType={UNIT_TYPE.WEIGHT}
                      onChange={handleUnitInputChange}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Read-only Unit input</label>
                    <UnitInput
                      readOnly={true}
                      value={100}
                      unitValue={"m"}
                      label="Length"
                      unitType={UNIT_TYPE.LENGTH}
                      onChange={handleUnitInputChange}
                    />
                  </Column>

                  <Column className="mb-10" gap={5}>
                    <label>Disabled Unit input</label>
                    <UnitInput
                      disabled={true}
                      value={100}
                      unitValue={"m"}
                      label="Length"
                      unitType={UNIT_TYPE.LENGTH}
                      onChange={handleUnitInputChange}
                    />
                  </Column>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={15}>
                  <Column className="mb-10" gap={5}>
                    <label>Unit input with an error message</label>
                    <UnitInput
                      value={undefined}
                      required={true}
                      disabled={false}
                      label="Weight"
                      unitType={UNIT_TYPE.WEIGHT}
                      onChange={handleUnitInputChange}
                      validationMessage="This field is required"
                    />
                  </Column>
                </Grid>
              </Column>
            </ComponentWrapper>
          </form>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "customerStore",
  "studioStore"
)(observer(ComponentTestPage));
