import React, { useState, useEffect, useMemo } from "react";
import "./advanced.list.data.table.component.scss";
import { inject, observer } from "mobx-react";
import GlobalSearchStore, { SearchTopic } from "stores/global.search.store";
import StudioStore from "stores/studio.store";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  LOCAL_STORAGE_SORT_KEY,
  LOCAL_STORAGE_FILTER_KEY,
  getLocalStorageOption,
  updateLocalStorageOption,
  getLocalStorageSortData,
  getLocalStorageFilterData,
  removeLocalStorageOptionByID,
  LocalSortFilterOption,
  SortOrder,
  FilterValue,
} from "globals/helpers/storage.helper";
import { debounce } from "lodash";
import {
  PaginationData,
  PaginationType,
} from "components/footer.component/footer.component";

import ListDataTable, {
  DataRow,
  DataTableHeader,
  ListDropdownMenuProps,
} from "components/table.components/list.data.table.component/list.data.table.component";
import Filter from "components/table.components/filter.component/filter.component";
import SearchInput from "components/input.components/search.input.component/search.input.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

// type of the data table item selection
export const enum SelectType {
  CHECKBOX = "CHECKBOX",
  ROW = "ROW",
}

interface AdvancedListDataTableProps<T> {
  id: string;
  searchTopic: SearchTopic;
  newItemPath: string;
  translationPath: string;
  columns: DataTableHeader[];
  itemClassName?: string;
  tableClassName?: string;
  gap?: number;
  disableHeader?: boolean;
  disableSort?: boolean;
  sortable?: boolean;
  selectable?: boolean;
  selectType?: SelectType;
  selectedItems?: any[];
  noDataMessage?: string;
  isLoading?: boolean;
  isSelectMode?: boolean;
  isPagination?: boolean;
  paginationData?: PaginationData;
  paginationType?: PaginationType;
  listDropdownMenu?: ListDropdownMenuProps<T>;
  fetchData: (
    args: LocalSortFilterOption,
    isPagination?: boolean
  ) => Promise<T[] | undefined | any>;
  searchData: (
    value: string,
    args?: {
      isPagination: boolean;
      isScroll: boolean;
      pageIndex: number;
      itemsInPage: number;
    }
  ) => Promise<T[] | undefined | any>;
  dataTableItemBuilder: (data: T, index: number) => DataRow;
  onClick?: (
    data: T,
    index: number,
    event: React.MouseEventHandler<HTMLDivElement>
  ) => void;
  onSelect?: (data: T | T[]) => void;
  onSortEnd?: (oldIndex: number, newIndex: number) => void;
  handleSelectedItems?: (action: string, func: (args: any) => void) => void;
  onToggleSelectMode?: (value: boolean) => void;
  renderCustomBulkActions?: () => JSX.Element;
  globalSearchStore?: GlobalSearchStore;
  studioStore?: StudioStore;
}

const AdvancedListDataTable = <T extends unknown>({
  id,
  searchTopic,
  newItemPath,
  translationPath,
  columns,
  itemClassName,
  tableClassName,
  gap = 0,
  disableHeader = false,
  disableSort = false,
  sortable = false,
  selectable = true,
  selectType = SelectType.ROW,
  selectedItems,
  noDataMessage,
  isLoading,
  isSelectMode = false,
  isPagination = false,
  paginationData,
  paginationType,
  listDropdownMenu,
  fetchData,
  searchData,
  dataTableItemBuilder,
  onClick,
  onSelect,
  onSortEnd,
  handleSelectedItems,
  onToggleSelectMode,
  renderCustomBulkActions,
  globalSearchStore: searchStore,
  studioStore,
}: AdvancedListDataTableProps<T>): JSX.Element => {
  const currentLanguage = studioStore?.currentLanguage;

  const navigate = navigationHelper();

  const { t } = useTranslation();
  const translate = createTranslate(t, translationPath);

  const [data, setData] = useState<T[]>([]);
  const [isClearFilter, setClearFilter] = useState(false);
  const [isDisableFilter, setDisableFilter] = useState(false);
  const [isClearSort, setClearSort] = useState(false);
  const [isSortable, setSortable] = useState(sortable);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = (): void => {
    getSortedAndFilteredData({});
  };

  const getSortedAndFilteredData = async (
    args: LocalSortFilterOption
  ): Promise<void> => {
    const sortOption = getLocalStorageOption(LOCAL_STORAGE_SORT_KEY, id);
    const filterOption = getLocalStorageOption(LOCAL_STORAGE_FILTER_KEY, id);

    if (!args.sortByProp && !args.sortOrder) {
      args.sortByProp = sortOption?.sortByProp;
      args.sortOrder = sortOption?.sortOrder;
    }

    if (!args.filterValues) {
      args.filterValues = filterOption?.filterValues;
    }

    if (isPagination) {
      args.isPagination = isPagination;
    }

    const items = await fetchData(args);

    if (items || items?.data) {
      if (items.data) {
        setData(items.data);
      } else {
        setData(items);
      }

      // update sort options in local storage
      if (args.sortOrder && args.sortByProp) {
        updateLocalStorageOption(
          LOCAL_STORAGE_SORT_KEY,
          {
            sortByProp: args.sortByProp,
            sortOrder: args.sortOrder,
          },
          id
        );
      }

      // update filter options in local storage
      if (args.filterValues) {
        updateLocalStorageOption(
          LOCAL_STORAGE_FILTER_KEY,
          { filterValues: args.filterValues },
          id
        );
      }
    }
  };

  const getFilterOptions = (): FilterValue[] => {
    const values: FilterValue[] = [];

    columns.forEach((col) => {
      if (!col.filterBy || !col.filterValues || !col.filterByName) {
        return;
      }

      const element: FilterValue = {
        columnValue: col.filterBy,
        columnName: col.filterByName,
        values: col.filterValues,
      };
      values.push(element);
    });

    return values;
  };

  const filterOptions: FilterValue[] = getFilterOptions();

  const debounceSearch = async (value: string, args?: any): Promise<void> => {
    const data = await searchData(value, args);

    if (data) {
      setData(data);
    }
  };

  const debounceSearchFn = useMemo(() => debounce(debounceSearch, 700), []);

  const handleSearchChange = (value: string, args?: any): void => {
    if (value) {
      // clear Filter and Sort data when searching
      removeLocalStorageOptionByID(LOCAL_STORAGE_FILTER_KEY, id);
      removeLocalStorageOptionByID(LOCAL_STORAGE_SORT_KEY, id);
      setClearFilter(true);
      setClearSort(true);

      // disable sort functionality when searching data
      setSortable(false);
      // disable filter functionality when searching data
      setDisableFilter(true);
      // set search value for pagination
      setSearchValue(value);

      debounceSearchFn(value, args);
    }

    if (!value) {
      // set the initial sortable value when stop searching data
      setSortable(sortable);
      // enble filter functionality when searching data
      setDisableFilter(false);

      getSortedAndFilteredData({
        sortByProp: "system.modifiedAt",
        sortOrder: "desc",
      });
    }
  };

  const handleChangePage = (page: number): void => {
    // Search field isn't empty
    if (searchValue) {
      handleSearchChange(searchValue, { isPagination, pageIndex: page });
      return;
    }

    getSortedAndFilteredData({ pageIndex: page });
  };

  const handleScrollPage = (): void => {
    // Search field isn't empty
    if (searchValue) {
      handleSearchChange(searchValue, { isPagination, isScroll: true });
      return;
    }

    getSortedAndFilteredData({ isScroll: true });
  };

  const handleSelectPageItems = (itemsInPage: number): void => {
    // Search field isn't empty
    if (searchValue) {
      handleSearchChange(searchValue, { isPagination, itemsInPage });
      return;
    }

    getSortedAndFilteredData({ itemsInPage });
  };

  const __buildDefaultBulkActions = (): JSX.Element => {
    return (
      <>
        <FilledButton
          disabled={!selectedItems?.length}
          className="advanced-delete"
          icon={faTrashAlt}
          label={translate("delete")}
          onClick={() => {
            if (handleSelectedItems) {
              handleSelectedItems("archive", getSortedAndFilteredData);
            }
          }}
        />
      </>
    );
  };

  const __buildBulkActions = (): JSX.Element => {
    return (
      <>
        {renderCustomBulkActions?.()}
        {__buildDefaultBulkActions()}
      </>
    );
  };

  return (
    <div className="advanced-list-data-table">
      <div className="advanced-list-data-table-header">
        <Row className="table-header-search-section" gap={10}>
          <SearchInput
            searchPath="/api/admin/exercises/search/"
            placeholder="Search..."
            onChange={(value: string) => {
              if (isPagination) {
                handleSearchChange(value, {
                  isPagination,
                  pageIndex: 0,
                  isScroll: false,
                });
              } else {
                handleSearchChange(value);
              }
            }}
          />

          {filterOptions && !isSelectMode && (
            <Filter
              language={currentLanguage}
              isDisableFilter={isDisableFilter}
              isClearFilter={isClearFilter}
              initialFilterData={getLocalStorageFilterData(id)}
              options={filterOptions}
              onFilter={async (filterValues: FilterValue[]) => {
                await getSortedAndFilteredData({ filterValues });
              }}
            />
          )}
        </Row>

        <Row
          gap={10}
          className="table-header-select-section"
          justifyContent="flex-end"
        >
          {!isSelectMode && (
            <FilledButton
              className="advanced-add"
              icon={faPlus}
              label={translate("addButton")}
              onClick={() => navigate(newItemPath)}
            />
          )}
          {selectable && isSelectMode && (
            <>
              {__buildBulkActions()}

              <FilledButton
                className="advanced-cancel"
                label={translate("cancel")}
                color="light"
                onClick={() => {
                  if (onToggleSelectMode) {
                    onToggleSelectMode(false);
                  }
                }}
              />
            </>
          )}

          {selectable && !isSelectMode && (
            <FilledButton
              className="advanced-select"
              label={translate("select")}
              color="light"
              onClick={() => {
                if (onToggleSelectMode) {
                  onToggleSelectMode(true);
                }
              }}
            />
          )}
        </Row>
      </div>

      <ListDataTable
        data={data}
        selectType={selectType}
        selectedItems={selectedItems}
        columns={columns}
        gap={gap}
        dataTableItemBuilder={dataTableItemBuilder}
        itemClassName={itemClassName}
        tableClassName={tableClassName}
        onClick={onClick}
        onSelect={onSelect}
        disableHeader={disableHeader}
        sortable={isSortable && !isSelectMode}
        onSortEnd={onSortEnd}
        noDataMessage={noDataMessage}
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        isLoading={isLoading || searchStore?.isLoading}
        isSelectMode={isSelectMode}
        isPagination={isPagination}
        paginationData={paginationData}
        paginationType={paginationType}
        handleChangePage={handleChangePage}
        handleScrollPage={handleScrollPage}
        handleSelectPageItems={handleSelectPageItems}
        listDropdownMenu={listDropdownMenu}
        disableSort={disableSort}
        onSort={async (sortByProp: string, sortOrder: SortOrder) => {
          await getSortedAndFilteredData({ sortByProp, sortOrder });
        }}
        initialSortData={getLocalStorageSortData(id)}
        isClearSort={isClearSort}
      />
    </div>
  );
};

export default inject(
  "globalSearchStore",
  "studioStore"
)(observer(AdvancedListDataTable));
