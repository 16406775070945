import React, { useState, useEffect, InputHTMLAttributes } from "react";
import classNames from "classnames";
import "./outlined.text.input.component.scss";
import { faEye, faEyeSlash, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OutlinedTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputRef?: any;
  inputRefValue?: any; // use with inputRef to catch the value and set the label
  label?: string;
  suffixIcon?: React.ReactNode;
  validationMessage?: string;
  initialValue?: string;
  onChange?: (value: any) => void;
  allowDecimalNumbers?: boolean;
  color?: "primary" | "secondary";
  onClear?: () => Promise<void>;
}

const OutlinedTextInput = ({
  className,
  label,
  inputRef,
  inputRefValue,
  type = "text",
  suffixIcon,
  validationMessage,
  initialValue,
  onChange,
  allowDecimalNumbers = false,
  color = "primary",
  onClear,
  ...props
}: OutlinedTextInputProps): JSX.Element => {
  const { required, value, disabled, readOnly, placeholder, onClick } = props;

  const [showPassword, setShowPassword] = useState(true);
  const [inputType, setInputType] = useState(type);
  const [currentValue, setCurrentValue] = useState(null as any);

  useEffect(() => {
    if (value) setCurrentValue(value);
    if (inputRefValue) setCurrentValue(inputRefValue);
  }, [value, inputRefValue]);

  // if input type is password then show suffix icon depending on showPassword state
  if (type === "password") {
    suffixIcon = showPassword ? (
      <FontAwesomeIcon icon={faEye} />
    ) : (
      <FontAwesomeIcon icon={faEyeSlash} />
    );
  } else if (type === "text" && currentValue) {
    suffixIcon = (
      <FontAwesomeIcon icon={faClose} className="suffix-icon--secondary" />
    );
  }

  let rOnChange: any = null;
  let restInputRef = {};

  if (inputRef) {
    const { onChange: rOnC, ...restInputR } = inputRef;
    rOnChange = rOnC;
    restInputRef = restInputR;
  }

  const handleChange = (e: any): void => {
    if (onChange) {
      onChange(e.target.value);
    }

    if (rOnChange) {
      rOnChange(e);
    }
    setCurrentValue(e.target.value);
  };

  const outlinedTextInputClassName = classNames(
    {
      "outlined-text-input-container": true,
    },
    className
  );

  const inputFieldClassName = classNames({
    "default-input-field": true,
    "default-input-field--disabled": disabled,
    "default-input-field--readonly": readOnly,
    "default-input-field--error": validationMessage != null,
    "default-input-field--secondary": color === "secondary",
  });

  const inputLabelClassName = classNames({
    "default-input-label": label != null,
    "default-input-label--disabled": disabled,
    "default-input-label--readonly": readOnly,
    "default-input-label--on-top": placeholder ?? currentValue,
    "default-input-label--error": validationMessage != null,
  });

  const inputRequiredClassName = classNames({
    "required-field": true,
    "required-field--disabled": disabled,
    "required-field--readonly": readOnly,
    "required-field--error": validationMessage != null,
  });

  const suffixIconClassName = classNames({
    "suffix-icon": true,
    "suffix-icon--secondary": onClear,
  });

  return (
    <div className={outlinedTextInputClassName}>
      <input
        disabled={disabled}
        autoFocus={true}
        step={inputType === "number" && allowDecimalNumbers ? 0.01 : undefined}
        {...restInputRef}
        className={inputFieldClassName}
        type={inputType}
        value={currentValue}
        placeholder={placeholder}
        readOnly={readOnly}
        defaultValue={initialValue}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (
            type === "number" &&
            !(
              e.code.match(/Digit/g) ??
              e.code.match(/Arrow/g) ??
              (e.code === "Backspace" || e.code === "Delete")
            )
          ) {
            e.preventDefault();
          }
        }}
        required={required}
      />
      {label != null && (
        <label aria-required className={inputLabelClassName}>
          {validationMessage ?? label}
          {required && <span className={inputRequiredClassName}> *</span>}
        </label>
      )}
      {!disabled && !readOnly && (
        <div
          className={suffixIconClassName}
          onClick={() => {
            // when input type is password, toggle the password visibility
            // otherwise handle onClick event from OutlinedTextInputProps
            if (type !== "password") {
              setCurrentValue("");

              onClear?.();
              return onClick;
            } else {
              setShowPassword(!showPassword);
              setInputType(showPassword ? "text" : "password");
            }
          }}
        >
          {suffixIcon}
        </div>
      )}
    </div>
  );
};

export default OutlinedTextInput;
